<template>
  <v-container fluid>
    <v-form ref="form" class="card-datos">
      <div class="row">
        <div class="col-12 py-0"><datosPersonales tipo="precotizacion" /></div>
        <div class="col-12 py-0"><datosServicios tipo="precotizacion" /></div>
        <div class="col-12 py-0">
          <v-file-input
            v-model="archivos"
            counter
            show-size
            truncate-length="50"
            chips
            label="Archivos"
            accept=".png,.jpg,.pdf,.doc,.docx"
            multiple
          >
          </v-file-input>
        </div>

        <div class="col-12" text-right>
          <v-flex text-right>
            <v-btn
              color="success"
              small
              outlined
              @click="guardar"
              :disabled="guardarFlag"
            >
              Guardar</v-btn
            >
          </v-flex>
        </div>
        <!--  -->
      </div>
    </v-form>
  </v-container>
</template>

<script>
import funcion from "@/mixins/funciones";
import Swal from "sweetalert2";
import axios from "axios";
import { mapMutations, mapState, mapActions } from "vuex";
export default {
  mixins: [funcion],
  data() {
    return {
      archivos: null,
      verServicios: false,
      mensaje: "",
      guardarFlag: true,
    };
  },
  mounted() {
    this.getQuoteStatus();
  },
  components: {
    datosPersonales: () =>
      import("@/components/Cotizacion/cardDatosPersonalesComponent.vue"),
    datosServicios: () =>
      import("@/components/Cotizacion/cardServicioComponent.vue"),
    // cargarAchivos: () =>
    //   import("@/components/comun/cargarArchivosComponet.vue"),
  },
  name: "cardDatosPersonalesComponent",
  computed: {
    ...mapState([
      "entities",
      "modality",
      "shipment",
      "incoterm",
      "status",
      "stepCarga",
      "datosPrincipales",
      "services",
      "costos",
    ]),
  },
  methods: {
    ...mapActions(["getQuoteStatus", "getMultiplicador"]),
    ...mapMutations([
      "setServices",
      "setQuoteStatus",
      "setEntities",
      "setCostos",
      "setProvincias",
      "setDistritos",
    ]),
    async getItemsServices(idmodality, idshipment, idincoterm) {
      let data = {
        id_modality: idmodality,
        id_shipment: idshipment,
        id_incoterms: idincoterm,
        id_branch: JSON.parse(localStorage.getItem("branch")),
      };
      let valores = await this.obtenerDataApi(
        "post",
        process.env.VUE_APP_URL_MAIN + "getItemsServices",
        data
      );
      let servicios = [];

      for (let i = 0; i < valores.length; i++) {
        valores[i].groupservice.forEach((element) => {
          servicios.push({
            id_groupservices: element.id_groupservices,
            id_incoterms: element.id_incoterms,
            id_modality: element.id_modality,
            id_multiplicador: element.id_multiplicador
              ? element.id_multiplicador
              : 5,
            id_services: element.id_services,
            id_shipment: element.id_shipment,
            namebegend: element.namebegend,
            namegroupservice: element.namegroupservice,
            namemultiplicador: element.namemultiplicador,
            namerole: element.namerole,
            price: element.price,
            id: element.id,
            id_begend: element.id_begend,
            nameservice: element.nameservice,
            status: element.status,
            id_role: element.id_role,
            esventaflag: 0,
            statedelete: 0,
            servicioFlag: 1,
            costosflag: 0,
            codegroupservices: element.codegroupservices,
            codeItemservices: element.codeItemservices,
          });
        });
      }
      this.guardarServicios(servicios);
    },

    guardarServicios(servicios) {
      this.setServices(servicios);
      this.getItemsServicesDetails(
        this.datosPrincipales.idmodality,
        this.datosPrincipales.idshipment.value,
        this.datosPrincipales.idincoterm
      );
    },

    async getItemsServicesDetails() {
      this.guardarFlag = false;
      this.$router
        .push({
          name: "cardServicioPreCotizacion",
          hash: "#servicio",
        })
        .catch(() => {});
      let datos = {
        id_modality: this.datosPrincipales.idmodality,
        id_shipment: this.datosPrincipales.idshipment.value,
        id_incoterms: this.datosPrincipales.idincoterm,
        id_branch: JSON.parse(localStorage.getItem("branch")),
      };
      let valores = await this.obtenerDataApi(
        "post",
        process.env.VUE_APP_URL_MAIN + "getItemsServicesDetails", //9800
        datos
      );

      let servicios = [];
      for (let i = 0; i < valores.length; i++) {
        valores[i].groupservice.forEach((element) => {
          servicios.push({
            id_groupservices: element.id_groupservices,
            id_incoterms: element.id_incoterms,
            id_modality: element.id_modality,
            id_multiplicador: element.id_multiplicador
              ? element.id_multiplicador
              : "N",
            id_services: element.id_services,
            id_shipment: element.id_shipment,
            namebegend: element.namebegend,
            namegroupservice: element.namegroupservice,
            namemultiplicador: element.namemultiplicador,
            namerole: element.namerole,
            costounitario: element.price ? element.price : 0,
            id: element.id,
            id_begend: element.id_begend,
            nameservice: element.nameservice,
            status: element.status,
            id_role: element.id_role,
            statedelete: 0,
            servicioFlag: 1,
            costosflag: 0,
            cif: parseFloat(0.35),
            seguro: parseFloat(0.45),
            id_proveedor: element.id_proveedor ? element.id_proveedor : 0,
            esopcionflag: 1,
            esventaflag: 0,
            esorigenflag:
              element.id_modality == 1
                ? element.id_begend == 1 ||
                  element.id_begend == 3 ||
                  element.id_begend == 6 ||
                  element.id_begend == 8
                  ? 1
                  : 0
                : element.id_begend == 1 ||
                  element.id_begend == 3 ||
                  element.id_begend == 6 ||
                  element.id_begend == 8
                ? 1
                : 0,
            eslocalflag:
              element.id_modality == 1
                ? (element.id_begend == 2 || element.id_begend == 7) &&
                  element.id_role != 4 &&
                  element.id_role != 3 &&
                  element.id_role != 7
                  ? 1
                  : 0
                : (element.id_begend == 1 || element.id_begend == 6) &&
                  element.id_role != 4 &&
                  element.id_role != 3 &&
                  element.id_role != 7
                ? 1
                : 0,

            esaduanaflag:
              element.id_modality == 1
                ? (element.id_begend == 2 || element.id_begend == 7) &&
                  (element.id_role == 4 || element.id_role == 3)
                  ? 1
                  : 0
                : element.id_begend == 1 &&
                  (element.id_role == 4 || element.id_role == 3)
                ? 1
                : 0,
            esalmacenflag:
              element.id_modality == 1
                ? (element.id_begend == 2 || element.id_begend == 7) &&
                  element.id_role == 7
                  ? 1
                  : 0
                : (element.id_begend == 1 || element.id_begend == 7) &&
                  element.id_role == 7
                ? 1
                : 0,
          });
          servicios.push({
            id_groupservices: element.id_groupservices,
            id_incoterms: element.id_incoterms,
            id_modality: element.id_modality,
            id_multiplicador: element.id_multiplicador
              ? element.id_multiplicador
              : "N",
            id_services: element.id_services,
            id_shipment: element.id_shipment,
            namebegend: element.namebegend,
            namegroupservice: element.namegroupservice,
            namemultiplicador: element.namemultiplicador,
            namerole: element.namerole,
            costounitario: 0,
            id: element.id,
            id_begend: element.id_begend,
            nameservice: element.nameservice,
            status: element.status,
            id_role: element.id_role,
            cif: 0,
            seguro: 0,
            statedelete: 0,
            costosflag: 0,
            id_proveedor: element.id_proveedor,
            esventaflag: 1,
            esorigenflag:
              element.id_modality == 1
                ? element.id_begend == 1 ||
                  element.id_begend == 3 ||
                  element.id_begend == 6 ||
                  element.id_begend == 8
                  ? 1
                  : 0
                : element.id_begend == 1 ||
                  element.id_begend == 3 ||
                  element.id_begend == 6 ||
                  element.id_begend == 8
                ? 1
                : 0,
            eslocalflag:
              element.id_modality == 1
                ? (element.id_begend == 2 || element.id_begend == 7) &&
                  element.id_role != 4 &&
                  element.id_role != 3 &&
                  element.id_role != 7
                  ? 1
                  : 0
                : (element.id_begend == 1 || element.id_begend == 6) &&
                  element.id_role != 4 &&
                  element.id_role != 3 &&
                  element.id_role != 7
                ? 1
                : 0,

            esaduanaflag:
              element.id_modality == 1
                ? (element.id_begend == 2 || element.id_begend == 7) &&
                  (element.id_role == 4 || element.id_role == 3)
                  ? 1
                  : 0
                : element.id_begend == 1 &&
                  (element.id_role == 4 || element.id_role == 3)
                ? 1
                : 0,
            esalmacenflag:
              element.id_modality == 1
                ? (element.id_begend == 2 || element.id_begend == 7) &&
                  element.id_role == 7
                  ? 1
                  : 0
                : (element.id_begend == 1 || element.id_begend == 7) &&
                  element.id_role == 7
                ? 1
                : 0,
          });
        });
      }
      this.guardaCostos(servicios);
    },

    guardaCostos(servicios) {
      this.setCostos(servicios);
    },
    async guardar() {
      let imp =
        this.services.filter((v) => v.codegroupservices == 15).length > 0
          ? this.services.filter((v) => v.codegroupservices == 15)[0].status
          : false;
      let aduana =
        (this.services.filter((v) => v.codegroupservices == 2).length > 0
          ? this.services.filter((v) => v.codegroupservices == 2)[0].status
          : false) ||
        (this.services.filter((v) => v.codegroupservices == 6).length > 0
          ? this.services.filter((v) => v.codegroupservices == 6)[0].status
          : false);
      if (!this.datosPrincipales.amount && (imp || aduana)) {
        Swal.fire({
          icon: "error",
          title: "Ocurrió un error",
          text: "Para servicios Aduanas o Impuesto se requiere un monto",
        });
      } else {
        let serv = [];
        this.services
          .filter((v) => v.esventaflag == 0)
          .forEach((element) => {
            serv.push({
              idBegEnd: element.id_begend,
              nameservice: element.namegroupservice,
              statusService: element.status,
              id_groupservices: element.id_groupservices
                ? element.id_groupservices
                : "",
              codegroupservices: element.codegroupservices,
              codeItemservices: element.codeItemservices,
              id_begend: element.id_begend,
            });
          });
        /* GUARDAR COSTOS */
        let cost = [];
        this.costos
          .filter((v) => v.statedelete == 0)
          .forEach((element) => {
            cost.push({
              id_proveedor: element.id_proveedor,
              id_multiplicador: element.id_multiplicador,
              concepto: element.nameservice,
              costounitario: element.costounitario,
              esorigenflag: element.esorigenflag,
              eslocalflag: element.eslocalflag,
              esaduanaflag: element.esaduanaflag,
              esalmacenflag: element.esalmacenflag,
              esopcionflag: element.esventaflag == 1 ? 1 : 0,
              status: element.status,
              esventaflag: element.esventaflag,
              cif: element.cif,
              seguro: element.seguro,
            });
          });
        /* GUARDAR NOTAS */
        let notas = [];

        this.datosPrincipales.notesFixed
          .filter((v) => v.state == 1)
          .forEach((element) => {
            notas.push({
              descripcion: element.description,
              esprincipalflag: 1,
              esincluyeflag: 0,
              esnoincluyeflag: 0,
              status: element.state ? element.state : 1,
            });
          });

        /* GUARDAR CONTENEDORES */
        let cont = [];
        if (this.datosPrincipales.idshipment.value == 2) {
          this.datosPrincipales.containers.forEach((element) => {
            cont.push({
              id_contenedor: element.id,
              cantidad: element.cantidad,
            });
          });
        } else {
          cont = [];
        }
        let data = {
          fullflag: false,
          id_entitie: this.datosPrincipales.idCliente,
          idPricing: this.datosPrincipales.idPricing,
          idVendedor: this.datosPrincipales.identities,
          idsentido: this.datosPrincipales.idmodality,
          idtipocarga: this.datosPrincipales.idshipment.value,
          idincoterms: this.datosPrincipales.idincoterm,
          idorigen: this.datosPrincipales.idPortBegin,
          iddestino: this.datosPrincipales.idPortEnd,
          numerobultos: this.datosPrincipales.bultos,
          peso: this.datosPrincipales.kg,
          volumen: this.datosPrincipales.metroscc,
          statusquote: 1,
          monto: this.datosPrincipales.amount,
          serviciocotizacion: serv,
          costocotizacion: cost,
          ventascasillerodetalles: [],
          notacosto: notas,
          contenedores: cont,
          proveedor: this.datosPrincipales.proveedor,
          telefonoproveedor: this.datosPrincipales.telefonoproveedor,
          direccionproveedor: this.datosPrincipales.direccionproveedor,
          descripcionMercancia: this.datosPrincipales.descripcioncarga,
          iddistrito: this.datosPrincipales.iddistrito,
          idprovincia: this.datosPrincipales.idprovincia,
          impuestos: this.datosPrincipales.impuestos,
          id_branch: JSON.parse(localStorage.getItem("branch")),
        };

        let config = {
          method: "post",
          url: process.env.VUE_APP_URL_MAIN + "setQuote",
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")),
            "Content-Type": "application/json",
          },
          data: data,
        };

        const response = await axios(config);

        this.subirArchivos(
          response.data.insertId,
          this.archivos,
          response.data.msg,
          0
        );
      }
    },

    async subirArchivos(id, datos, msg, index) {
      let i = index;

      if (datos.length > i) {
        let formData = new FormData();
        // var valores = null;
        formData.append("name", this.uuidv4());
        formData.append("file", datos[i]);
        formData.append("id_quote", id);
        formData.append(
          "id_branch",
          JSON.parse(localStorage.getItem("branch"))
        );

        axios
          .post(process.env.VUE_APP_URL_MAIN + "uploadFilesSingle", formData, {
            "Content-Type": "multipart/form-data",
            "auth-token": JSON.parse(localStorage.getItem("token")),
          })
          .then((response) => {
            i++;
            if (response.data.statusBol == true) {
              this.subirArchivos(id, datos, msg, i);
            }
          });
      } else {
        Swal.fire({
          title: "CORRECTO",
          text: msg,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push("listado").catch(() => {});
          }
        });
      }
    },
    /**
     *  OBTENER PROVINCIAS
     */

    // async getCityPricing() {
    //   let valores = await this.obtenerDataApi(
    //     "post",
    //     process.env.VUE_APP_URL_MAIN + "getCityPricing",
    //     { idState: 15 }
    //   );
    //   let provincias = [];
    //   valores.forEach((element) => {
    //     provincias.push({
    //       value: element.id,
    //       text: element.name,
    //     });
    //   });
    //   this.setProvincias(provincias);
    //   this.getTown();
    // },
    /**
     *  OBTENER DISTRIOS
     */

    // async getTown() {
    //   let valores = await this.obtenerDataApi(
    //     "post",
    //     process.env.VUE_APP_URL_MAIN + "getTown",
    //     { idCity: 128 }
    //   );
    //   let distritos = [];
    //   valores.forEach((element) => {
    //     distritos.push({
    //       value: element.id,
    //       text: element.name,
    //     });
    //   });
    //   this.setDistritos(distritos);
    // },
    // async getEntities() {
    //   let data = {
    //     id_module: 6,
    //   };
    //   let valores = await this.obtenerDataApi(
    //     "post",
    //     process.env.VUE_APP_URL_MAIN + "getModulesEntities",
    //     data
    //   );
    //   let entities = [];
    //   valores.forEach((element) => {
    //     entities.push({
    //       value: element.id_entitie,
    //       text: element.name,
    //     });
    //   });

    //   this.setEntities(entities);
    // },
  },
  watch: {
    stepCarga() {
      this.getMultiplicador(this.datosPrincipales.idshipment.value);
      this.getItemsServices(
        this.datosPrincipales.idmodality,
        this.datosPrincipales.idshipment.value,
        this.datosPrincipales.idincoterm
      );
    },
  },
};
</script>

<style scoped>
.card-datos {
  min-height: 100%;
}
</style>
